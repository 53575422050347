import React from 'react'


export default function Button({name, url, nametest, className, style, image, onClickHandler, disable, type}) {
    return (
        <button
        onClick={onClickHandler}
        data-value={url}
        type={type ?  type : "button" }
        className={className} 
        style={{...style}}
        disabled={disable}
        data-test={nametest}
        >
            <img src={image} alt="save" className="mr-1 my-1"/> 
            <span className="mb-0" > {name} </span>
        </button>
    )
}

import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';

const IpDetails = ({ ipDetails, host, test }) => {

    const [isCopied, setCopied] = useState({ ip: false, hostname: false });
    const { ip, hostname } = isCopied;

    const copyToClipBoard = (text, name) => {

        copy(text, {
            debug: true,
            message: "Press KEY"
        });
        setCopied({ ...isCopied, [name]: true });
    }

    useEffect(() => {
        const update = 
            setTimeout(() => {
                setCopied({ ip: false, hostname: false });
            }, 2000);
        return () =>{
             clearTimeout(update);
        }
    }, [ip, hostname])


    return (
        <>
            {!test && <h6 className="table-head mb-3 pl-2 pt-2" 
            style={{ fontSize: "1.5em" }}> Your IP Details  </h6>}
            <table className="table table-hover" style={{ width: "100%", overflow: "hidden" }} >
                <tbody className="table-body">
                    <tr className="table-row">
                        <th scope="row"> Your IP </th>
                        <td> : </td>
                        <td onClick={() =>
                            copyToClipBoard(ipDetails.ip, 'ip')}>
                            {ip ? "Copied" : ipDetails.ip} </td>
                    </tr>
                    <tr className="table-row table-element">
                        <th scope="row"> Hostname</th>
                        <td> : </td>
                        <td onClick={() =>
                            copyToClipBoard((ipDetails.hostName === "") ? ipDetails.hostName : host, 'hostname')}>
                            {hostname ? "Copied" : ipDetails.hostName === "" ? ipDetails.hostName : host} </td>
                    </tr>
                    <tr className="table-row table-element">
                        <th scope="row">City</th>
                        <td> : </td>
                        <td>{ipDetails && ipDetails.cityName}</td>
                    </tr>
                    <tr className="table-row table-element">
                        <th scope="row">Country</th>
                        <td> : </td>
                        <td> {ipDetails.countryName}
                            <img alt={ipDetails.cc && ipDetails.cc} width={30} className="ml-2 mb-1"
                                src={`/flags/${ipDetails.cc && ipDetails.cc.toLowerCase()}.png`} />
                        </td>
                    </tr>
                    <tr className="table-row table-element">
                        <th scope="row">Location</th>
                        <td> : </td>
                        <td>{ipDetails && ipDetails.location && ipDetails.location.latitude} / {" "}
                            {ipDetails && ipDetails.location && ipDetails.location.longitude} </td>
                    </tr>
                    <tr className="table-row table-element">
                        <th scope="row">Postal</th>
                        <td> : </td>
                        <td> {ipDetails && ipDetails.postal} </td>
                    </tr>
                    <tr className="table-row table-element">
                        <th scope="row">Region</th>
                        <td> : </td>
                        <td> {ipDetails && ipDetails.region} </td>
                    </tr>
                    {test && <tr className="table-row table-element">
                        <th scope="row">User Agent</th>
                        <td> : </td>
                        <td> {ipDetails && ipDetails.userAgent && ipDetails.userAgent['source']} </td>
                    </tr>}
                </tbody>


            </table>
        </>
    )
}

export default IpDetails;
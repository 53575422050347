import React from 'react'
import SystemCard from './SystemCard';


export default function SystemDetails({ headers, userAgent }) {

    return (
        <SystemCard
            title={"Your System Info"}
            items={[
                { name: "Operating System", value: userAgent ?  `${userAgent['os']}` : "-" },
                { name: "Platform", value: userAgent ? `${userAgent['platform']}` : "-"},
                { name: "Version", value: userAgent ? `${userAgent['version']}` : "-" },
                { name: "Browser", value: userAgent ? `${userAgent['browser']}` :'-' },
                { name: "HTTP Encoding", value: headers ? `${headers['accept-encoding']}` :"-" },
                { name: "HTTP Accept Language", value: headers ? `${headers['accept-language']}`: "-" },
                { name: "User Agent", value: userAgent ?  `${headers['user-agent']}` : '-'},
                { name: "HTTP Connection", value: userAgent ? `${headers['connection']}` : "-"}
            ]}
        />
    )
}

import React, { useRef, useEffect, useContext } from "react";
import { ipDetailsContext} from '../../context/dnsServers';
import { CLOSE, MODAL_BROWSER_CLEAR } from '../../context/index';

function useOutside(ref) {
    const context = useContext(ipDetailsContext);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                context.dispatch({
                    type: CLOSE
                })
                // context.dispatch({
                //     type: MODAL_POS,
                //     payload: false
                // })
                context.dispatch({
                    type: MODAL_BROWSER_CLEAR,
                })
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

export default function (props) {
    const wrapperRef = useRef(null);
    useOutside(wrapperRef);
    return <div ref={wrapperRef}>{props.children}</div>;
}

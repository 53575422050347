import React, { useCallback, useEffect, useContext } from 'react';
import Button from '../test/Actions/Button'
import Redo from '../test/Icons/Redo.svg'
import Add from '../test/Icons/Add.svg'
import ResultItem from './ResultItem';
import CaretDown from '../UI/CaretDown';
import { IS_SAVED_DOH, SORT_DOH_RESULT, DOH_RESULT_DELETE_CUSTOM } from '../../context/index';
import { ipDetailsContext } from '../../context/dnsServers';
import axios from 'axios';


export default function Result({ onGenerate, setModalHandler, disable, isLoading }) {


    const context = useContext(ipDetailsContext)
    const { dohResult, dohResultCustom, isUp, isSavedDoh } = context.state;
    const { dispatch } = context;

    const sortHandler = (pos) => {
        dispatch({
            type: SORT_DOH_RESULT,
            payload: pos
        })
    }
    // console.log("dohCustomResult", dohResultCustom);
    const onSaveSpeedTest = useCallback(
        async (items, cItems) => {
            let data = items && items.length > 0 && items.map(item => {
                return {
                    name: item.provider,
                    url: item.url,
                    latency: item.latency
                }
            });
            let customData = cItems && cItems.length > 0 && cItems.map(item => {
                return {
                    name: item.provider,
                    url: item.url,
                    latency: item.latency === "" ? 0 : item.latency
                }
            });
            let joinedData = [...data];
            if (customData) joinedData = [...data, ...customData]
            const jsonData = JSON.stringify(joinedData);
            await axios.post('/api/doh/speed/save', { data: jsonData })
        }, []);

    useEffect(() => {
        if (!isSavedDoh && !isLoading)
            setTimeout(() => {
                onSaveSpeedTest(dohResult, dohResultCustom);
            }, 8000)
        dispatch({
            type: IS_SAVED_DOH,
            payload: true
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isSavedDoh, dispatch])

    const deleteCookieHandler = (provider) => {

        document.cookie = "customdoh_" + provider + '=; max-age=-0;';
        context.dispatch({
            type: DOH_RESULT_DELETE_CUSTOM,
            payload: { name: provider }
        })
    }


    return (
        <div className="d-flex flex-column">
            <div className="card dohserver-card mt-2">
                <table className="table table-hover table-responsive-sm">
                    <thead className="card-header">
                        <tr className="d-table-row">
                            <th>Provider <small> (*Custom) </small></th>
                            <th>DoH Server</th>
                            <th onClick={() => sortHandler(!isUp)} className="latency">Latency
                                <CaretDown className={isUp ? "caret-down rotate__up" : "caret-down"} /> </th>
                            <th>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dohResultCustom.length > 0 && dohResultCustom.map((item,i) =>
                            <ResultItem
                                key={item.url+i.toString()}
                                provider={item.provider}
                                latency={item.latency}
                                result={item.result}
                                url={item.url}
                                slot={item.addsNumber}
                                plus={item.adds}
                                custom
                                onClickHandlerDel={deleteCookieHandler}
                            />
                        )}
                        {dohResult.length > 0 && dohResult.map(item => {
                            return (
                                <ResultItem
                                    key={Math.random().toString(16)}
                                    provider={item.provider}
                                    latency={item.latency}
                                    result={item.result}
                                    url={item.url}
                                    slot={item.addsNumber}
                                    plus={item.adds}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div style={{ alignSelf: "flex-end" }}>
                <Button
                    name="Add DoH Server"
                    className="btn btn-info my-3 mr-3"
                    image={Add}
                    onClickHandler={setModalHandler}
                />
                <Button name="Retest"
                    className="btn btn-primary my-3"
                    image={Redo}
                    type="submit"
                    disable={!disable}
                    onClickHandler={onGenerate}
                />
            </div>
        </div>
    )
}

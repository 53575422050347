import React, { useContext, useState, useEffect } from 'react'
import TestPoller from './TestPoller';
import { ipDetailsContext, } from '../../context/dnsServers';
import { IS_SAVED, IS_SHARED, CLOSE, DNS_SERVERS, DNS_SERVERS_CURRENT } from '../../context/index';
import ShareSave from './Actions/ShareSave';
import axios from 'axios';
import { wait } from './helper';
import IpDetailsTest from './IpDetailsTest';
// import MapsGoogle from './MapsGoogle';
import OpenStreet from './LeafletMap';
import Progress from '../UI/Progress';
import copy from 'copy-to-clipboard';

const Testing = ({ onCancelHandler, cont, setCont, percentage, setStateTimer, 
  shared, active, setActive, onRegenerateHandler, identfy, setIdentfy }) => {

  const context = useContext(ipDetailsContext);
  const { dispatch, state } = context;
  let { ipDetails, hostName, dnsServers } = state;
  const [isLoading, setIsLoading] = useState(false);

  const { location, ip } = ipDetails;
  const [isSharedLink, setIsSharedLink] = useState('');
  const [stateCookie, setStateCookie] = useState([])
  const [linesMapGoogle, setLinesMapGoogle] = useState([]);

  const copyToClipBoard = (text) => {
    copy(text, {
      debug: true,
      message: "Press KEY"
    });
  }


  const checkDnsecho = async (cnt, ident, signal) => {
    try {
      setIsLoading(true);

      const res = await axios.get(`/api/dnsecho/check/${ident}`, { signal: cnt >= 10 && signal })
      if (res && res.hasOwnProperty('config') && res.config.hasOwnProperty('signal') && res.config.signal.hasOwnProperty('aborted') && res.config.signal.aborted) {
        setIsLoading(false);
        return;
      }
      if (res?.['data']?.['data']
        && Array.isArray(res.data.data) && cnt < 10) {
        let totalCount = 0;
        res.data.data.forEach(item => {
          totalCount = totalCount + item.count
        })
        dispatch({
          type: DNS_SERVERS,
          payload: { data: res.data.data, count: totalCount }
        })
        dispatch({
          type: DNS_SERVERS_CURRENT,
          payload: { data: res.data.data, count: totalCount }
        })
      }
      if (cnt >= 10) {
        setIdentfy('', dispatch)
        setIsLoading(false);
        return;
      };
      if (cnt > 0) {
        await wait(1 + cnt * 0.5)
        setStateTimer(value => value - (cnt * 0.5 + 1));
      }
      setCont(prev => prev + 1);

    } catch (_) {
      setIsLoading(false)
    }
  }







  let leaktest_hostname = identfy !== '' ? `${identfy}.test.${window.location.host}/test` : null

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (identfy !== '' && cont <= 10) {
      // console.log('identifier1', identfy, cont)
      checkDnsecho(cont, identfy, signal)
    }
    return () => { controller.abort(); };
  }, [identfy, cont]);

  useEffect(() => {
    let lat;
    let lng;
    let linesMapList = [];
    let linesMapGoogle = [];

    dnsServers.forEach(item => {
      if (item?.location?.latitude !== lat || item?.location?.longitude !== lng) {
        linesMapList.push({
          from: [item?.location?.longitude, item?.location?.latitude],
          to: [location?.longitude ?? 32.01, location?.latitude ?? 32.01]
        })
        linesMapGoogle.push([{ lat: item?.location?.latitude, lng: item?.location?.longitude },
        { lat: location?.latitude ?? 32.01, lng: location?.longitude ?? 32.01 }])
      }

      lng = item?.location?.latitude;
      lat = item?.location?.longitude;
    });
    // setLinesMap(linesMapList);
    setLinesMapGoogle(linesMapGoogle)
  }, [dnsServers, location])

  /// retest function

  //// gettting cookies
  const getCookies = () => {
    const data = document.cookie;
    const newData = data.split("; ");
    let updateData = [];
    newData.forEach(item => {
      let key = item.split("=")[0];
      let value = item.split("=")[1];
      if (key !== "" && key !== "cookieconsent_status" && !key.includes("customdoh") && !key.includes("_ga") && key.endsWith("Z"))
        updateData.push({ name: value, date: [key] })
    });
    setStateCookie(updateData);
  }

  //// sharing function
  const onShareHandler = async (name) => {
    if (active !== 0) return;
    try {

      let resp;
      let shareLink;
      let saveLink;
      let updatedData = dnsServers.map(item => {
        item.cc = item?.cc ?? "-";
        item.countryName = item?.countryName ?? "-"
        item.cityName = item?.cityName ?? "-"
        item.region = item?.region ?? "-"
        item.postal = item?.postal ?? "-"
        item.location = item?.location ?? {}
        item.isp = item?.isp ?? '-'
        item.asnOrg = item?.asnOrg ?? '-'
        item.asnNumber = item?.asnNumber ?? '-'
        // let ip = item?.ip ?? '-';
        // item.hostName = rdap?.[ip] ?? "-";
        return item;
      });

      if (!isLoading) {
        resp = await axios.post(`/api/dns-share`, {
          data: JSON.stringify(updatedData),
          hostName: hostName !== '' ? hostName : "Not available" ,
        })

        if (resp?.data?.retcode === 200) {
          const createdDate = resp?.data?.createdDate;
          shareLink = `https://${window.location.host}/dns-leak-test/${resp?.data?.id}`;
          saveLink = `https://${window.location.host}/api/dns-shares/${resp?.data?.id}.json`;
          copyToClipBoard(shareLink)
          setIsSharedLink(shareLink);
          if (name === "save") {
            let cookiename = ip + "/" + new Date(createdDate).toISOString();
            context.dispatch({
              type: IS_SAVED,
              payload: true
            })
            document.cookie = `${cookiename}=${saveLink};max-age=2592000`;
            return getCookies();
          }
          context.dispatch({
            type: IS_SHARED,
            payload: true
          })
        }
      }
    } catch (_) {
      context.dispatch({
        type: CLOSE
      })
    }
  }

  useEffect(() => {
    getCookies();
  }, []);

  return (

    <div>
      {!shared && leaktest_hostname && [...Array(50)].map((e, i) => <img
        alt="1px"
        key={i}
        className='testingimage'
        src={`https://${i}.` + leaktest_hostname + i + '.png'} />)}
      <div className="row no-gutters">
        <div className="col-12">
          <h1 className="text-center text-h1-header"> {shared ? "Shared" : ""} DNS Leak Test for
            <span className="text-muted"> {ip}
              {ipDetails.cc && <img
                className="ml-2 mb-1"
                src={`/flags/${ipDetails?.cc?.toLowerCase()}.png`}
                alt={ipDetails?.cc} width={40} />}
            </span>
          </h1>
          {!isLoading && <ShareSave
            onRegenerate={onRegenerateHandler}
            onShare={onShareHandler}
            onDisabled={isLoading}
            isSharedlink={isSharedLink}
            active={active}
            shareButton={shared}
          />}
          <div className="google-map"
            style={isLoading ? { height: "184px" } : {}}>
            {isLoading && <div className="d-flex align-items-center p-3 my-3"
              style={{ flexDirection: "column" }}>
              <div data-value={percentage} data-test='percentage'>
                <Progress percentage={percentage} /> </div>
              <button
                data-test='enough_button'
                disabled={dnsServers.length === 0}
                className="btn btn-danger my-3"
                type="button"
                onClick={() => onCancelHandler()}>Enough Data</button></div>}
            {!isLoading && <OpenStreet id="dns-leak-test" lat={ipDetails?.['location']?.['latitude']}
              lng={ipDetails?.['location']?.['longitude']}
              lines={linesMapGoogle}/>
            // <MapsGoogle
            //   lat={ipDetails?.['location']?.['latitude'] ?? 0.00}
            //   lng={ipDetails?.['location']?.['longitude'] ?? 0.00}
            //   lines={linesMapGoogle}
            // />
            }
          </div>
          <TestPoller
            dataPast={stateCookie}
            isLoading={isLoading}x
            dnsservers={dnsServers}
            active={active}
            setActive={setActive}
            getCookies={getCookies}
            setCont={setCont}
            shared={shared}
          />
          <h2 className="text-center mt-lg-3 mt-2 pt-3" style={{ fontSize: "1.8em" }}>
            DNS Servers performing queries for your host</h2>
          <p className="text-4"> We perform a DNS Leak test to determine which DNS Servers your host/browser is probably using. Our tests are intense and aimed to find every DNS server you are using. Sometimes you might need to test several times to catch a leak. Obvious leaks will be visible at the first test. Some providers, such as Google/Cloudflare, are using thousands of relays; that is why you might see dozens of servers from these providers.</p>
        </div>
      </div>

      <IpDetailsTest
        ipDetails={ipDetails}
        host={hostName} />
    </div>
  )

}

export default Testing;
import { useEffect, useState } from 'react'
import L, { LatLng, } from 'leaflet'


const locIcon = L.icon({
  iconUrl: '/my-icon.svg',
  iconSize: [32, 32],
  iconAnchor: [16, 16],
//   popupAnchor: [-3, -76],
});



export default function OpenStreet({ id, lat, lng, lines }) {

    useEffect(() => {
        if (!document.getElementById(id)) {
            return;
        }
        if (!lat && !lng) {
            return
        }
        // if (!map1) {
           const map1 = new L.Map(id, {
                center: new LatLng(lat, lng),
                zoom: 4,
                minZoom: 2,
                maxZoom: 8
            });
            // setMap(map1)
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', 
            { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }).addTo(map1)
            //   const layerGroup = L.layerGroup().addTo(map1)
            L.marker(new LatLng(lat, lng), {icon: locIcon}).addTo(map1)
            lines.map((item, i) => {
                console.log("item", item)
                L.polyline([new LatLng(item[0].lat, item[0].lng), new LatLng(item[1].lat, item[1].lng)]).addTo(map1)
                // L.polyline([new LatLng(lat, lng), new LatLng(item.lat, item.lng)])
            })


        // }

        // return () => {
        //     if (map1) {
        //         map1.remove()
        //     }
        // }
    }, [id, lat, lng])




    return (
        <div id={id} style={{ zIndex: 9, height: "26rem", width: "100%" }}></div>
    )
}

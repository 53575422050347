import React, {useMemo} from 'react';
// import {Loader} from '@googlemaps/js-api-loader';
const apiKey= process.env.REACT_APP_MAP_API_KEY

export default function Location({lat, lng}) {

    // const idGoogleRef = useRef(null);
    // console.log("location",  lat, lng)
  
    // useEffect(() => {
    //   if(idGoogleRef.current !== null){
    //     const loader = new Loader({
    //       apiKey: process.env.REACT_APP_MAP_API_KEY,
    //       version: 'weekly',
    //       libraries:["places"]
    //     });
    //     let map;
    //     loader.load().then((google) => {
    //       if(idGoogleRef.current !== null){
    //         map = new google.maps.Map(idGoogleRef.current, {
    //           center: {lat: lat, lng: lng},
    //           zoom: 10,
    //         });
    //         new google.maps.Marker({
    //           position: {lat: lat, lng: lng},
    //           map,
    //           title: "Ip Location",
    //         });
    //       }}); 
    //   }
    //   }, [ lat, lng]);


    // return (
    //     <div 
    //         style={{height:"100%", width:"100%"}}
    //         className="google-map"
    //         ref={idGoogleRef}
    //     >
    //     </div>
    // )

  const map = useMemo(() => {

    if (!lat && !lng) {
        return <div className="g-map">
            <div className="flex justify-center md:justify-end">
                <div className="g-map flex flex-col items-center justify-center w-full h-full border-solid border-2 border-gray-100">
                    {/* <img src="/nomap.svg" alt="nomap found" className="w-1/5" /> */}
                    <p className="mt-10 text-lg">Location is unavailable </p>
                </div>
            </div>
        </div>
    }
  
      return <iframe
          // width="600"
          // height="450"
          className="google-map"
          style={{ border: "0", width: "100%", height: "100%" }}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/view?key=${apiKey}&zoom=9&center=${lat},${lng}`}>
      </iframe>
  }, [lat, lng])


  return map
}

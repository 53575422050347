export const CHECK_API_SERVER = "CHECK_API_SERVER";
export const LOADING= "LOADING";
export const SET_IP_DETAILS = "SET_IP_DETAILS";
export const SET_IP_DETAILS_CURRENT = "SET_IP_DETAILS_CURRENT";
export const RESET_IDENTIFIER = "RESET_IDENTIFIER";
export const SET_LAST_IDENTIFIER = "SET_LAST_IDENTIFIER";
export const RESET_LAST_IDENTIFIER = "RESET_LAST_IDENTIFIER";
export const DNS_SERVERS = 'DNS_SERVERS';
export const DNS_SERVERS_CURRENT ="DNS_SERVERS_CURRENT";
export const HOSTNAME = "HOSTNAME";
export const RDAP = "RDAP";
export const CLOSE = "CLOSE";
export const IS_SAVED = "IS_SAVED";
export const IS_SHARED = "IS_SHARED";

// export const IS_FIRST_RUN = "IS_FIRST_RUN";
export const IS_FIRST_RUN_DOH = "IS_FIRST_RUN_DOH";
export const DOH_SERVERS = "DOH_SERVERS";
export const DOH_RESULT = "DOH_RESULT";
export const SORT_DOH_RESULT = "SORT_DOH_RESULT";
export const MODAL_POS = "MODAL_POS";
export const IS_SAVED_DOH = "IS_SAVED_DOH";
export const DOH_RESULT_ADD_CUSTOM = "DOH_RESULT_ADD_CUSTOM";
export const DOH_RESULT_DELETE_CUSTOM = "DOH_RESULT_DELETE_CUSTOM";
export const DOH_RESULT_UPDATE_CUSTOM = "DOH_RESULT_UPDATE_CUSTOM";

export const MODAL_BROWSER = "MODAL_BROWSER";
export const MODAL_BROWSER_CLEAR = "MODAL_BROWSER_CLEAR";
import React, { useReducer, useEffect, useState } from 'react';
import { validHostname } from '../UI/domain';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_RESET = 'INPUT_RESET';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            }
        case INPUT_RESET:
            return {
                value: '',
                isValid: false,
                touched: false,
            }
        default:
            return state;
    }
};



export default function Input({
    onChangeInput,
    initialValue,
    initialValid,
    required,
    label,
    name,
    type,
    completed,
    min
}) {

    const handleFocus = (event) => event.target.select();
    const [error, setError] = useState("");

    const [inputState, dispatchInputState] = useReducer(inputReducer, {
        value: initialValue ? initialValue : '',
        isValid: initialValid ? initialValid : false,
        touched: false,
    })

    useEffect(() => {
        onChangeInput(name, inputState.value, inputState.isValid);
    }, [name, inputState.value, inputState.isValid, onChangeInput]);

    useEffect(() => {
        if (completed){
            dispatchInputState({
                type: INPUT_RESET
            })
            setError("");
        }
    }, [completed])

    const onChangeHandler = (event) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!]))?/;
        let isValid = true;
        if (name === "hostname" && !validHostname(event.target.value)) {
            isValid = false;
            setError("Invalid hostname");
        }
        if (name === "url" && !urlRegex.test(event.target.value)) {
            isValid = false;
            setError("Invalid Url");
        }

        if (min !== null && +event.target.value.trim().length < min) {
            isValid = false;
            setError(`You must enter at least minumum ${min} character`);
        }
        if (required && event.target.value.trim().length === 0) {
            isValid = false;
            setError("This field cannot be left blank");
        }

        dispatchInputState({
            type: INPUT_CHANGE,
            value: event.target.value.trim(),
            isValid: isValid
        })
 
    }


    return (
        <div className="my-3">
            <label htmlFor={name} className="text-bold"> {label} </label>
            <input className="form-control form-control-lg"
                onFocus={handleFocus}
                onChange={onChangeHandler}
                type={type}
                id={name}
                name={name}
                autoFocus={false}
                value={inputState.value} />
            {!inputState.isValid &&
            <div className="invalid-feedback" 
                style={{ display: "block" }}> {error} 
            </div>}
        </div>
    )
}

import React from 'react'

export default function SystemCard({ title, items }) {
    return (
        <>
        {/* <h6 className="table-head mb-3 pl-2" style={{fontSize:"1.5em"}}> {title} </h6> */}
        <table className="table table-hover">
            <tbody className="table-body">
                {items.map((item, i) => {
                    return (
                        <tr key={item.name} className={i > 0 ? "table-element": ""}>
                            <th scope="row"> {item.name} </th>
                            <td> : </td>
                            <td style={{ wordWrap: "break-word" }}>
                                {item.value}
                            </td>
                        </tr>
                    )
                }
                )}
            </tbody>
        </table>
        </>

    )
}

import React, { useState, useEffect, useReducer, useCallback, useContext } from 'react';
import { ipDetailsContext } from '../../context/dnsServers';
import {
  MODAL_POS, IS_FIRST_RUN_DOH, DOH_SERVERS,
  DOH_RESULT, IS_SAVED_DOH, DOH_RESULT_ADD_CUSTOM
} from '../../context/index';
import Input from './Input';
import Result from './Result';
import axios from 'axios';
import ConfigurationDns from './ConfigurationDns';
import doh from 'dohjs';
import Loading from '../UI/Loading';
import AddDohServer from './modal/AddDohServer';
import { getDohSpeedTest } from '../test/helper'

const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
const FORM_INPUT_RESET = 'FORM_INPUT_RESET';

const formReducer = (state, action) => {

  if (action.type === FORM_INPUT_UPDATE) {
    return {
      inputValue: action.value,
      inputValidation: action.isValid,
    }
  }
  else if (action.type === FORM_INPUT_RESET) {
    return {
      inputValue: "google.com",
      inputValidation: true,
    }
  }
  return state;
}

export default function DnsOverHttps() {

  const context = useContext(ipDetailsContext);
  const { dohResult, dohServers, isFirstRunDoh } = context.state;
  const { dispatch } = context;
  const [isLoading, setLoading] = useState(false);
  const completeDoh = false;


  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValue: "google.com",
    inputValidation: true,
  })

  const api_dns_over_https = async (dohs, inp) => {
    let items = [];
    setLoading(true);
    dohs.forEach(item => {
      const startTime = new Date().getTime();
      let resolver = new doh.DohResolver(item.url);
      resolver.query(inp, 'A').then(resp => {
        const endTime = new Date().getTime();
        const latency = endTime - startTime;
        const item_server = {
          provider: item.provider,
          url: item.url,
          latency: latency,
          result: resp.rcode === "NOERROR" ? resp.answers[0].data : resp.rcode,
          addsNumber: resp.answers.length > 1 ? (resp.answers.length - 1).toString() : "",
          adds: resp.answers.length > 1 ? resp.answers : []
        };
        items.push(item_server)
        dispatch({
          type: DOH_RESULT,
          payload: items
        });
        setLoading(false)
      })
        .catch((err) => {
          console.error(err)
          setLoading(false)
        })
    })
    dispatch({
      type: IS_FIRST_RUN_DOH,
      payload: false
    })
  }



  // dohspeestest save

  const dohServersGet = async () => {
    try {

      const res = await axios.get('/api/doh/servers');
      // console.log(res);
      if (res.data.retcode === 200) {
        context.dispatch({
          type: DOH_SERVERS,
          payload: res.data.servers
        });
        if (formState.inputValidation) {
          await api_dns_over_https(res.data.servers, formState.inputValue);
        }
      }
    } catch (err) {

    }
  }

  useEffect(() => {
    if (isFirstRunDoh) {
      dohServersGet();
      getCookies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRunDoh])



  const onChangeInputHandler = useCallback((inputIdentifier, inputValue, inputValidty) => {
    dispatchFormState({
      type: FORM_INPUT_UPDATE,
      value: inputValue,
      isValid: inputValidty,
    });
  }, [dispatchFormState])



  const setModalHandler = (pos) => {
    context.dispatch({
      type: MODAL_POS,
      payload: pos
    })
  }

  const getCookies = () => {

    const data = document.cookie;
    const newData = data.split("; ");
    let updateData = [];
    newData.forEach(item => {
      let key = item.split("=")[0];
      let value = item.split("=")[1];
      if (key.includes("customdoh"))
        updateData.push({ provider: key.split("_")[1], url: value })
    });

    let cookie_doh = [];
    if (updateData.length > 0) {
      updateData.forEach(async (item) => {
        let itemValue = await getDohSpeedTest(item.url, formState.inputValue, item.provider);
        cookie_doh.push(itemValue)
      })
    }
    dispatch({
      type: DOH_RESULT_ADD_CUSTOM,
      payload: cookie_doh
    })
  }




  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (formState.inputValidation) {
      context.dispatch({
        type: IS_SAVED_DOH,
        payload: false
      })
      await api_dns_over_https(dohServers, formState.inputValue);
      getCookies();
    }
  }


  return (
    <div className="container my-lg-5 my-3">
      <AddDohServer
        searchValue={formState.inputValue} />
      <div className="row">
        <div className="col-12">
          <h1 className="my-3 text-h1-header">DNS-over-HTTPS</h1>
          <div className="row mt-3">
            <div className="col-12">
     
                  <p className="text-4"><a href='https://en.wikipedia.org/wiki/DNS_over_HTTPS' rel="noreferrer" target="_blank">DoH(DNS Over HTTPS)</a> is a new standard which is more and more taking the control of <a href='https://en.wikipedia.org/wiki/Domain_Name_System' rel="noreferrer" target="_blank">DNS</a>. Especially browsers such as Firefox and Chrome push this new standard forward to detach from old udp based port 53 DNS Servers. DoH resolves some of the issues with help of https standard which will be used everywhere on the internet. DoH is a technique against <a href='https://en.wikipedia.org/wiki/DNS_spoofing' rel="noreferrer" target="_blank">DNS Spoofing</a>, <a href='https://en.wikipedia.org/wiki/Man-in-the-middle_attack' rel="noreferrer" target="_blank">Man-in-the-Middle Attacks</a>. Some countries with Internet filtering have trouble with this new standard and are blocking DoH Providers, same as VPN Providers. DoH is basically a VPN Service for DNS. This is a good step towards right direction. What DoH does not solve is the privacy of your internet history. Same as the old standard, DoH providers will also be able to see all websites you visit along timestamp, your ip, your location and can retain. That is why it is essential to pick your trusted provider. Since this is a new technology most browsers do this decission for you by using their own DNS Servers. We believe this is the wrong kind of way to manage this important decission.</p>
                  <p className="text-4">You can see some of publicly available DoH Servers and their speed from your browser. Please note that the latency should not be interpreted as absolute time, this time is relative to eachother. Depending on your location the speed will vary. Speed should not be the main decission point, Trust is more prevalent, since all DoH Providers can see (and maybe retain) your Data.</p>
               
            </div>
            <form onSubmit={onSubmitHandler} className="col-12 my-3">
              <Input
                onChangeInput={onChangeInputHandler}
                initialValue={formState.inputValue}
                initialValid={formState.inputValidation}
                completed={completeDoh}
                label="Hostname to query"
                required
                name="hostname"
                type="text"
              />
            </form>
            <div className="col-12">
              {isLoading && <div className="text-center"> <Loading /> </div>}
              {!isLoading && dohResult.length > 0 &&
                <Result
                  isLoading={isLoading}
                  isFirstRunDoh={isFirstRunDoh}
                  onGenerate={onSubmitHandler}
                  setModalHandler={setModalHandler}
                  disable={formState.inputValidation}

                />
              }
            </div>
            <div className="col-12">
              <ConfigurationDns />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useContext, useState, useEffect } from 'react'
import Testing from './Testing';
import { ipDetailsContext } from '../../context/dnsServers';
import ResultAnalysis from './ResultAnalysis';
import PreventingDnsLeak from './PreventingDnsLeak';
import { DNS_SERVERS, DNS_SERVERS_CURRENT } from '../../context/index';
import { ip2int, randomString, setIdentfy } from './helper';


export default function DnsLeakTest() {

    const context = useContext(ipDetailsContext);
    const { dispatch, state } = context;
    const { identifier, ipDetails } = state
    // const [identfy, setIdentfy] = useState('')

    const { ip } = ipDetails;
    const [timer, setStateTimer] = useState(31.5);
    const [cont, setCont] = useState(0);

    const onCancelHandler = () => {
        setCont(10);
    }

    const [active, setActive] = useState(0);

    const onRegenerateHandler = (ipd) => {
        dispatch({
            type: DNS_SERVERS_CURRENT,
            payload: { data: [], count: 0 }
        })
        dispatch({
            type: DNS_SERVERS,
            payload: { data: [], count: 0 }
        })
        if (ipd) {
            const identifier = ip2int(ipd).toString(16) + '-' + randomString(45);
            setIdentfy(identifier, dispatch)
        }
        setCont(0);
        setStateTimer(31.5);
        setActive(0);
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        onRegenerateHandler(ip);
    }, [ip])


    return (
        <div className="container my-5" >
            <Testing
                cont={cont}
                onRegenerateHandler={onRegenerateHandler}
                onCancelHandler={onCancelHandler}
                setCont={setCont}
                percentage={((1 - (timer / 31.5)).toFixed(2) * 100).toFixed(0)}
                setStateTimer={setStateTimer}
                shared={false}
                active={active}
                setActive={setActive}
                identfy={identifier}
                setIdentfy={setIdentfy}
            />
            <ResultAnalysis />
            <PreventingDnsLeak />
        </div>
    )
}

import React from 'react'


export default function Card({ title, paragraf }) {
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="text-card-header">{title}</h3>
            </div>
            <div className="card-body">
                <div className="card-row">
                    <div className="col">
                        <p className="text-4">{paragraf}  </p>
                        
                    </div>
                </div>
            </div>
            
        </div>

    )
}

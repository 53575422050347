import React from 'react'

export default function Chrome() {
    return (
        <svg height="50px" style={{enableBackground:"new 0 0 512 512"}} 
        viewBox="0 0 512 512"
            width="50px" xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink">
                <g id="_x35_6-chrome"><g><g>
                <g><path d="M198.96,288.716c-5.279-9.171-8.41-19.75-8.71-31.07c-0.899-36.31,27.771-66.48,64.11-67.4      c36.29-0.93,66.47,27.78,67.38,64.08c0.3,11.97-2.62,23.28-7.97,33.09c-10.87,19.931-31.78,33.69-56.101,34.32      C232.681,322.355,210.601,308.966,198.96,288.716z" style={{fill:"#F2F2F2"}} /></g></g>
                <g><path d="M212.258,281.086c-4.049-7.033-6.449-15.146-6.679-23.826     c-0.69-27.845,21.295-50.98,49.162-51.686c27.829-0.713,50.973,21.303,51.671,49.14c0.229,9.179-2.01,17.852-6.112,25.375     c-8.336,15.284-24.37,25.836-43.02,26.318C238.116,306.882,221.185,296.614,212.258,281.086z" style={{fill:"#4B8BF5"}} /></g>
                <g><path d="M313.78,287.426l-90.16,165.17c-92.59-15.15-164.32-94.36-166.77-191.561     c-1.261-49.68,15.79-95.57,45-131.22l0.06,0.05l97.05,158.851c11.641,20.25,33.721,33.64,58.71,33.02     c24.32-0.63,45.23-14.39,56.101-34.32L313.78,287.426z" style={{fill:"#19A15F"}} /></g>
                <g><path d="M455.15,250.965c2.78,109.99-84.13,201.4-194.11,204.18c-12.76,0.32-25.27-0.561-37.42-2.55     l90.16-165.17l-0.01-0.011c5.35-9.81,8.27-21.12,7.97-33.09c-0.91-36.3-31.09-65.01-67.38-64.08l188.08-4.59     C450.12,206.015,454.57,227.985,455.15,250.965z" style={{fill:"#FFCE42"}} /></g>
                <g><path d="M442.44,185.655l-188.08,4.59c-36.34,0.92-65.01,31.09-64.11,67.4     c0.3,11.32,3.431,21.899,8.71,31.07l-97.05-158.851l-0.06-0.05c35.449-43.27,88.83-71.45,149.12-72.96     C337.98,54.655,413.36,108.575,442.44,185.655z" style={{fill:"#DE5145"}} /></g></g></g><g id="Layer_1" /></svg>
    )
}

import React from 'react'
import Card from '../UI/Card'

export default function HomeThird() {
    return (
        <div className="container my-5">
            <div className="row no-gutters">
                <div className="col">
                    <Card title="Why Us ?"
                        paragraf="We are here to help the community and to bust the myths. DNS Leak is a technical-based issue, and for most regular internet users, this fact is not easily understood. Most DNS Leak websites will tell you to use a VPN to fix such issues. But we want to show you to open your eyes. DNS Data is essentially the x-ray of your internet behavior. You have to pick the right provider for yourself. Most of the more prominent providers bring you to speed as the main reason. Despite the fact this is essential, speed is half of the story. Privacy and Trust is the second half." />
                </div>
            </div>
        </div>
    )
}

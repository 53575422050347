import React from 'react';

export default function PrivacyParagraf({ paragraf, intent, split, splitOne, style }) {
    return (
        split ? <p style={intent ? { fontSize: "1.1em", textIndent: "1em", textAlign: "justify", ...style } : 
        { fontSize: "1.1em", textAlign: "justify", ...style }}>
            <span style={{ fontWeight: 600 }}>  {splitOne} </span> {paragraf} </p> :
            <p style={intent ? { fontSize: "1.1em", textIndent: "1em", textAlign: "justify", ...style } : 
            { fontSize: "1.1em", ...style }}> {paragraf} </p>
    )
}
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/logo_dns.png';
import ArrowTop from '../UI/ArrowUp';
import { animateScroll as scroller } from 'react-scroll'
import cn from 'classnames'

export default function Header() {
    const [isTop, setIsTop] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const eventEl = () => {
            if (window.scrollY !== 0) {
                setIsTop(window.scrollY)
            } else {
                setIsTop(0)
            }
        };
        window.addEventListener('scroll', eventEl, { passive: true });
        return (() =>
            window.removeEventListener('scroll', eventEl, { passive: true }))
    }, []);

    const onToggleHandler = () => {
        setIsMenuOpen(!isMenuOpen);
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        // scroller.scrollTo(0, {
        //     smooth: true,
        //     duration: 600,
        // })
    };


    return (
        <>
            <nav className={ cn("navbar navbar-expand-lg navbar-light bg-light box-shadow-special", {"header-fixed": isTop > 0})}
            >
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={Logo} alt="logo dns" width="100%" />
                    </Link>
                    <button className={cn("navbar-toggler", {'collapsed': !isMenuOpen} )}
                        type="button"
                        onClick={onToggleHandler}
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="toggler-icon top-bar"></span>
                        <span className="toggler-icon middle-bar"></span>
                        <span className="toggler-icon bottom-bar"></span>
                    </button>
                    <div className={cn("collapse navbar-collapse justify-content-end", {'show': isMenuOpen} )}
                        // style={{ justifyContent: "flex-end" }}
                        >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    data-test='home_link'
                                    onClick={() => setIsMenuOpen(false)}
                                    className="nav-link"
                                    style={{ fontSize: "1.2em" }}
                                    activeClassName="selected"
                                    activeStyle={{ fontWeight: "bold", color: "#39B54A" }}
                                    to="/" aria-current="page" >Home</NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink
                                    data-test='leaktest_link'
                                    onClick={() => setIsMenuOpen(false)}
                                    className="nav-link nav-item-before"
                                    style={{ fontSize: "1.2em" }}
                                    activeClassName="selected"
                                    activeStyle={{ fontWeight: "bold", color: "#39B54A" }}

                                    to="/dns-leak-test">DNS Leak Test</NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink
                                    data-test='fixing_link'
                                    onClick={() => setIsMenuOpen(false)}
                                    className="nav-link nav-item-before"
                                    style={{ fontSize: "1.2em" }}
                                    activeClassName="selected"
                                    activeStyle={{ fontWeight: "bold", color: "#39B54A" }}

                                    to="/fixing-dns-leak">Fixing DNS Leak</NavLink>
                            </li>
                            <li className="nav-item ">
                                <NavLink
                                    data-test='doh_link'
                                    onClick={() => setIsMenuOpen(false)}
                                    className="nav-link nav-item-before"
                                    style={{ fontSize: "1.2em" }}
                                    activeClassName="selected"
                                    activeStyle={{ fontWeight: "bold", color: "#39B54A" }}

                                    to="/dns-over-https">DNS-over-HTTPS</NavLink>
                            </li>


                        </ul>

                    </div>
                </div>
            </nav>
            <button className="top-button" onClick={scrollToTop}
                style={isTop > 20 ? { display: "block" } : { display: "none" }}>
                <ArrowTop />
            </button>
        </>
    );
}

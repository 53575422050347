import React, {useContext} from 'react'
import { ipDetailsContext } from '../../context/dnsServers';
import {
    MODAL_BROWSER
} from '../../context/index';
import OutsideClicker from './OutsideClicker'

const CardModal = ({ shown,  gif, title })  => {

    const context = useContext(ipDetailsContext);
 
    const { dispatch } = context;

    return (
        <OutsideClicker >
        <div className={shown ? "modal fade show" : "modal fade"}
            style={shown ? { display: "block", verticalAlign: "middle" } : { display: "none" }}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="browser"
            aria-hidden="true">
            <div className="modal-dialog"
                role="document"
                onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button"
                            className="close outline-none"
                            onClick={() => dispatch({
                                type: MODAL_BROWSER,
                                payload: {name: title, position: false}
                            })}
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="image-gif">
                            {gif ? <img src={gif} alt={title} height="auto" width="100%" /> : false}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </OutsideClicker>

    )
}


export default CardModal;
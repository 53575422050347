import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className="footer"
            style={{ backgroundColor: "#39B54A", bottom: "0", width: "100%" }}>
            <div className="container">
                <div className="row align-items-end py-2" >
                    <div className="col-12 col-md-4">
                        <div className="text-center text-md-left">
                            <p className="text-sm mb-0" style={{ fontSize: "0.8em", color: "black" }}>DNSleaktest.org</p>
                            <p className="text-sm mb-0" style={{ fontSize: "0.8em", color: "black" }}>©Copyright 2020-2021 All Rights Reserved.</p>

                        </div>
                    </div>
                    <div className="col-12 col-md-6" >
                        <div className="text-center text-md-left">
                            <a href="https://def24.com" target="_blank" rel="noreferrer"
                                className="nav-link privacy-link m-0 p-1" style={{ fontSize: "0.8em" }}> A Service of DEF24 Inc. Information Security Service. </a>
                        </div>
                    </div>

                    <div className="col-12 col-md-2">
                        <div className="text-center text-md-left">
                            <Link to="/privacy-policy" className="nav-link privacy-link m-0 p-1" style={{ fontSize: "0.8em" }}> Privacy Policy </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import CircularProgressBar from './CircularProgressBar';

const Progress = ({percentage}) => {
      return (
        <CircularProgressBar
        strokeWidth="6"
        sqSize="100"
        percentage={percentage}/>
      );
    
  }

  export default Progress;
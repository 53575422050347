import React, {useContext} from 'react';
import { useHistory } from "react-router-dom";
import { ipDetailsContext } from '../../../context/dnsServers';
import Button from './Button'
import Save from '../Icons/Save.svg'
import Share from '../Icons/Share.svg'
import Redo from '../Icons/Redo.svg'
import {
    EmailShareButton, WhatsappShareButton, WhatsappIcon, EmailIcon
} from "react-share";
import OutsideClicker from '../../UI/OutsideClicker'
import { animateScroll as scroller } from 'react-scroll'

const Share_Save = (
    { onRegenerate, onShare, onDisabled, isSharedlink, active, shareButton }) => {
        
    const context = useContext(ipDetailsContext);
    const {isShared, isSaved} = context.state;
    let history = useHistory();

    return (
        <OutsideClicker >
        <div className="row justify-content-center no-gutters my-md-3 my-1">
            <Button name="Retest" nametest={'retest_button'}
                className="btn btn-primary"
                image={Redo}
                onClickHandler={() =>{
                    if(shareButton){
                        history.push("/dns-leak-test")
                    } else onRegenerate("test")
                }}
                disable={onDisabled}
            />
            {!shareButton && 
            <div style={{ position: "relative" }}>
                <div className="popover" 
                style={isShared ? { display: "block", transform: "translate(0,0)" } : 
                { display: "none", transform: "translate(-100,0)" }}>
                    <EmailShareButton
                        subject="dns-leak"
                        body="Your DNS Leak test result url"
                        separator=": "
                        url={isSharedlink}
                        className="p-1"
                    >
                        <EmailIcon size="32" round />
                    </EmailShareButton>
                    <WhatsappShareButton title="dns-leak"
                        url={isSharedlink} className="p-1">
                        <WhatsappIcon size="32" round />
                    </WhatsappShareButton>
                </div>
                <Button 
                name={isShared ? "Copied": "Share"}
                nametest={'share_button'}
                url={isSharedlink}
                    className="btn btn-secondary ml-2"
                    onClickHandler={() =>  {
                        onShare("share");
                    }}
                    image={Share}
                    disable={isShared || onDisabled || active !== 0}
                    />
            </div>}

            {!shareButton && 
            <Button name={isSaved ? "Saved" : "Save"}
            nametest={'save_button'}
                className="btn btn-info ml-2"
                image={Save}
                disable={isSaved || onDisabled || active !== 0}
                onClickHandler={() => {
                    onShare("save")
                   
                    scroller.scrollTo(450, {
                        smooth: true,
                        duration: 500,
                    })
                }} />}
        </div>
        </OutsideClicker>
    )
}
export default Share_Save;
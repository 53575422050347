import axios from 'axios';
import { SET_IP_DETAILS, HOSTNAME, RESET_IDENTIFIER, SET_IP_DETAILS_CURRENT, CHECK_API_SERVER, LOADING} from '../../context/index';
import doh from 'dohjs';
import { doRequest } from './doRequest';

export const ip2int = (ip) => {
  return ip.split('.').reduce(function (ipInt, octet) { return (ipInt << 8) + parseInt(octet, 10) }, 0) >>> 0;
}

export const randomString = (length = 45, charSet = "abcdefghijklmnopqrstuvwxyz0123456789") => {
  var text = "";
  for (var i = 0; i < length; i++)
    text += charSet.charAt(Math.floor(Math.random() * charSet.length));
  return text;
}


export const wait = async (seconds) => {
  return new Promise(async function (resolve, reject) {
    setTimeout(() => { resolve(true) }, seconds * 1000)
  })
}

export const pingApi = async (context) => {
  context.dispatch({
    type: LOADING,
    payload: true
  })
  try {
    const responsePing = await doRequest('/api/ping', {}, "GET")
    // await axios.get(`http://dev1.def24.com/ping`);
    if (responsePing.retcode !== 200) {
      return context.dispatch({
        type: CHECK_API_SERVER,
        payload: false
      })
    }

    context.dispatch({
      type: CHECK_API_SERVER,
      payload: true
    })
    context.dispatch({
      type: LOADING,
      payload: false
    })
  }
  catch (err) {
    context.dispatch({
      type: LOADING,
      payload: false
    })
    return context.dispatch({
      type: CHECK_API_SERVER,
      payload: false
    })
  }
}


export const changeIdenfier = (ip, dispatch) => {
  let identifier = ip2int(ip).toString(16) + '-' + randomString(45);
  dispatch({
    type: RESET_IDENTIFIER,
    payload: identifier
  })
  return identifier;
}

export const setIdentfy = (ident, dispatch)=> {
  dispatch({
      type: RESET_IDENTIFIER,
      payload: ident
  })
}


export const getIpDetails = async (context, ping, ips) => {

  if (!ping) await pingApi(context);
  let ip;
  try {
    if (!ips.hasOwnProperty('ip')) {
      const resp = await doRequest('/api/ip', {}, "GET")
      // await axios.get(`http://dev1.def24.com/ip`);
      context.dispatch({
        type: SET_IP_DETAILS_CURRENT,
        payload: { data: resp }
      })
      context.dispatch({
        type: SET_IP_DETAILS,
        payload: { data: resp }
      })
      ip = resp.ip;
      
    } else {
      ip = ips.ip
      
    }
  } catch (err) {

  }
 

  try {
    if (ip) {
      const respDns = await axios.get(`/api/ip/rdns/${ip}`);
      if (respDns.data && respDns.data.data && Array.isArray(respDns.data.data) && respDns.data.data.length > 0) {
        context.dispatch({
          type: HOSTNAME,
          payload: respDns.data.data[0]
        })
      }
    }

  } catch (_) {
  }
}


export const getDohSpeedTest = async (url, host, provider) => {

  // console.log("helper", url, host, provider)
  const startTime = new Date().getTime();
  let resolver = new doh.DohResolver(url);
  try {
    const resp = await resolver.query(host, 'A')
    const endTime = new Date().getTime();
    const latency = endTime - startTime;
    return {
      provider: provider,
      url: url,
      latency: latency,
      result: resp.rcode === "NOERROR" ? resp.answers[0].data : resp.rcode,
      addsNumber: resp.answers.length > 1 ? (resp.answers.length - 1).toString() : "",
      adds: resp.answers.length > 1 ? resp.answers : []
    };

  } catch (err) {
    return {
      provider: provider,
      url: url,
      latency: "",
      result: "Failed",
      addsNumber: "",
      adds: []
    };
  }
}
import React from 'react'
import Paragraf from '../UI/Paragraf'
import CardList from '../UI/CardList'
import Chrome from './browserIcons/Chrome';
import Mozilla from './browserIcons/Mozilla';
import Edge from './browserIcons/Edge';
import Brave from './browserIcons/Brave';
import Opera from './browserIcons/Opera';
import Safari from './browserIcons/Safari';

export default function ConfigurationDns() {


    const firefox = [
        'Click the menu button.',
        'Select # Settings.',
        'In the General menu, scroll down to access # Network Settings.',
        'Click on the # Settings button.',
        'Click # Enable DNS-over-HTTPS. # Choose suitable provider or custom from the drop-down menu.',
        'Press # OK # to apply.'
    ];

    const google = [
        'Click on the three-dot menu at in the top-right corner of your chrome window.',
        'Click # Settings.',
        'Navigate to # Privacy and security > Security.',
        'Enable the # Use secure DNS # switch.',
        'Select # with Custom # from drop-down menu.',
        'Type your trusted providers DoH Server Url.',
    ];
    const edge = [
        'Go to # edge://settings/privacy.',
        'Scroll down to the + Security # section.',
        'Make sure the # Use secure DNS # option is enabled.',
        'Select # Choose a suitable provider.',
    ];

    const brave = [

        'Click the menu button in the top-right corner of your brave window.',
        'Navigate to # Settings # Top Menu.',
        'On the left side of the menu, Click on # Privacy and security #.',
        'Select # Security # inside Privacy and security section',
        'Enable # Use secure DNS.',
        'Click # With Custom # and Type your trusted providers DoH Server Url.'
    ];

    const opera = [
        "Click on the Opera icon to open the browser's menu.",
        `Pick Settings from the menu. Pressing # Alt+P (Windows) or ⌘+, (MAC) #  opens Settings directly.`,
        'In Settings, type #dns# on top search bar.',
        'Make sure to enable #Use DNS-over-HTTPS instead of the system`s DNS settings#',
        'Pick a suitable DoH provider.'
    ];

    const safari = [
        "Apple hasn’t implemented this feature in Safari yet.",
    ];

    return (
        <div className="my-5">
            <h2 className="mb-3">Configure your browser to use DNS-over-HTTPS </h2>
            <Paragraf paragraf="There are several browsers compatible with DNS-over-HTTPS (DoH) that you can use as DNS service provider in order to protect your DNS queries from privacy intrusions and tampering." />
            {/* Firefox */}
            <CardList title="Mozilla Firefox"
                name="mozilla"
                items={firefox}
                gif="/img/doh-firefox.gif"
                paragraf={<div>You may also type <b>about:preferences#general</b> as url to open the security options</div>}
            >
                <Mozilla />
            </CardList>

            <CardList
                title="Google Chrome"
                name="google"
                items={google}
                note="* This setting may already be enabled by default."
                paragraf={<div>You may also type <b>chrome://settings/security</b> as url to open the security options</div>}
                gif="/img/doh-chrome-windows.gif"
            >
                <Chrome />
            </CardList>
            <CardList title="Microsoft Edge"
                name="edge"
                items={edge} gif="/img/doh-edge-windows.gif" note="* This setting may already be enabled by default." >
                <Edge />
            </CardList>


            <CardList title="Brave"
                name="brave"
                items={brave}
                gif="/img/doh-brave-windows.gif"
                paragraf={<div>You may also type <b>brave://settings/security</b> as url to open the security options</div>}
            >
                <Brave />
            </CardList>
            <CardList
                name="opera"
                title="Opera"
                items={opera}
                gif="/img/doh-opera-windows.gif"
            >

                <Opera />
            </CardList>
            <CardList
                name="safari"
                title="Safari"
                items={safari}>
                <Safari />
            </CardList>
        </div>
    )
}

import React from 'react'
import Location from '../home/details/Location'
import IpDetails from '../home/details/IpDetails'

export default function IpDetailsTest({ ipDetails, host }) {

    return (
        <div className="card mt-lg-5 mt-3">
            <div className="card-header">
                <h3 className="text-card-header"> Your IP Details </h3>
            </div>
            <div className="row no-gutters justify-content-around overflow-hidden">
                <div className="col-12 col-lg-5 p-md-3 p-1 m-2" >
                    <IpDetails ipDetails={ipDetails} host={host} test={true} />
                </div>
                <div className="col-12 col-lg-6 m-2" style={{ minHeight: "400px" }}>
                    <Location
                        lat={ipDetails?.location?.['latitude']}
                        lng={ipDetails?.location?.['longitude']}
                    />
                </div>
            </div>
        </div>
    )
}

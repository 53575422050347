import React from 'react';
import { Link } from 'react-router-dom';
import StartButton from '../UI/StartButton';
import DNSLEAK from '../../assets/dnsleakimg2.png'
import DNSRESOLVER from '../../assets/dnsresolver.png'

export default function FixingDnsLeak() {
  return (
    <div className="container my-lg-5 my-3">
      <div className="row">
        <div className="col-12">
          <h1 className="my-3 text-h1-header"> What is a DNS Leak ? </h1>
          <div className="row mt-3">
            <div className="col-12 col-md-8">
                  <p className="text-4">A DNS Leak is that your DNS Queries (communication between you and your DNS Provider to determine IP Addresses of desired hostnames) go towards other DNS servers you do not know or forgot about or were even misconfigured. Most DNS Leaks are partial query leaks, meaning that part of your DNS Queries go to your desired DNS Provider and rest to unwanted, forgotten, misconfigured ones.
                  </p>
                    <img src={DNSLEAK} alt="dns-leak-test" width="100%" style={{ paddingTop: "15px" }} />

            </div>
            <div className="col-12 col-md-4 d-flex justify-content-center"
              style={{ alignSelf: "center", margin: "auto", justifySelf: "flex-end" }}>
              <Link to="/dns-leak-test" className="text-center my-3">
                <StartButton />
              </Link>
              {/* <Link to="/dns-leak-test" className="btn btn-primary btn-lg" > Start DNS Leak Test </Link> */}
            </div>
          </div>



          <h2 className="my-3 text-h1-header"> Why is DNS Leak a privacy issue ? </h2>
          <div className="row mt-3">
            <div className="col-12">
                  <p className="text-4">All websites and Apps need a DNS Query before being able to connect to the internet. We humans cannot remember IP Addresses; we need a DNS Resolver to 'resolve' hostname as an IP address, then our App, Browser, Operating System will do the communication through IP.</p>
                  <p className="text-4">A typical DNS Query would look like 'What is the IP address of google.com" and DNS Resolver would respond as 'The IP address of google.com is 1.2.3.4.</p>
                  <img src={DNSRESOLVER} alt="dns-leak-test" width="80%" style={{ paddingTop: "15px", paddingBottom: "15px" }} />
                  <p className="text-4">This Querying will leave your browsing history behind at your DNS Provider. All DNS Providers know which sites you will visit prior you can even visit. Resolvers also know your IP Address based on your IP Address location. Timestamp, Timezone, Date are part of the query. This fact leads to major privacy implications for you. DNS Providers can if they want to log date, time, timezone, IP, GeoIP, website and retain, sell, analyze, or even spoof (send you a fake response to bring your browser to a different website).</p>
                  <p className="text-4">At some point, you have to decide which DNS Provider you want to give your Internet History because, for most internet users, it is not possible to run a DNS resolver at home, even though this would be possible. Therefore, if you have decided which provider (ISP, VPN Provider, Uplink, Proxy Provider) your DNS Queries go, you want to make sure that your Queries go to this DNS provider.If not, this would be an issue for your browsing history.</p>    
            </div>
          </div>

          <h2 className="mb-3 text-h1-header"> Why does it happen ?</h2>
          <div className="row mt-3">
            <div className="col-12">
              <p className="text-4">Many reasons include misconfiguration, forgot DNS Settings, and wrong VPN/Proxy Provider. </p>
              <p className="text-4">Since DNS is essential and everything relies on it, DNS Settings need to be everywhere. At Operating System, at your Router, inside your browser, or even at App level. All routers (e.g., WIFI) do have DNS Settings, and you can change them on the Operating system and the Browser level. Unfortunately, this leads to confusion, and you sometimes forget to change it everywhere. Sometimes your DNS Server on browser level does not work, and your browser takes your Operating System DNS for relief. If your Operating System DNS does not work, it takes your Router DNS Server. This chain goes on like that.</p>
              <p className="text-4">If your internet is slow, your programs might take an alternative DNS Server at the upper level, leading to a leak if DNS Settings are different. That is the main reason why we do super-extended tests to force your browser to its limits.</p>
              <p className="text-4">If your VPN Provider is slow or does not respond, your DNS Queries might not go through your local settings for partial traffic. This is again a leak of your privacy.</p>
            </div>
          </div>


          <h2 className="mb-3 text-h1-header"> How can you prevent it ?</h2>
          <div className="row mt-3">
            <div className="col-12">
              <h4>If you are on a VPN Connection:</h4>
              <p className="text-4">It would help if you made sure a) the DNS Resolvers you see on our tests match with the Country you are connected to. b) DNS Resolvers belong to the VPN Provider.If VPN Provider sends your DNS Queries over the VPN to another provider, you will have no control over your privacy, and this VPN Provider cannot be trusted.At a VPN Level, you do not have much control over DNS Leaks. This is purely a VPN Providers issue. You might contact them and change if necessary. Since VPN Providers usually have thousands of servers, these settings might be different on every server. This fact makes your problem worse; inconsistency is worse.</p>
              <h4>If not:</h4>
              <p className="text-4" >Just be aware of different locations of DNS Settings; a) router b) operating system c) browser d) some apps. Decide which DNS Provider you trust, and make sure to set all your DNS Settings at all levels to the same provider. You may refer to Google, Youtube to find out locations to set your DNS Settings on those four categories. The first three categories are the most important ones. Lookup and make sure you know all the settings in your environment. There are tons of Router models, Operating Systems, and browsers. It is in your best interest to know DNS Settings in your environment for your privacy.</p>
            </div>

          </div>

          <h2 className="mb-3 text-h1-header"> Busting Myths </h2>
          <div className="row mt-3">
            <div className="col-12">
              <p className="text-4">
                One of the myths is that you would need a VPN Provider to prevent DNS Leak. This is not true! Unfortunately, most Leak tests are from VPN Providers or their affiliates, which leads to this intentional misconception. If you followed our guides above, you can still prevent a leak. Even the fact that some VPN Providers do have leaking DNS Settings makes things worse for you.
                Most people who care about such Leaks are aware of VPN for their privacy, which leads to these myths. But you do not need a VPN to take care of your DNS Privacy.
                A good VPN makes things better for you because you would not need to care for local DNS Settings. But at times, your VPN is down or your disconnect, your DNS might still leak. So it would be best if you first took care of your local issues then protected yourself with a VPN as a secondary privacy shield.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

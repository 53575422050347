import { ipDetailsContext } from '../../context/dnsServers';
import React, { useState, useContext } from 'react'
import SystemDetails from './details/SystemDetails';
import IpDetails from './details/IpDetails';
import { Link } from 'react-router-dom';
import Location from './details/Location';
import StartButton from '../UI/StartButton';


export default function HomeFirst() {

    const [state, setState] = useState(0);
    const context = useContext(ipDetailsContext);
    const { ipDetails, hostName } = context.state;

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-12 col-lg-6 pt-lg-5 mt-lg-3 order-lg-1 order-2" 
                style={{ width: "100%", overflow: "hidden" }}>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <button className={state === 0 ? "nav-link btn-nav active" : "nav-link btn-nav"}
                                aria-current="page"
                                onClick={() => setState(0)}
                            >IP Details</button>
                        </li>
                        <li className="nav-item">
                            <button className={state === 1 ? "nav-link btn-nav active" : "nav-link btn-nav "}
                                onClick={() => setState(1)}
                                aria-current="page"
                            >Location</button>
                        </li>
                        <li className="nav-item">
                            <button className={state === 2 ? "nav-link btn-nav active" : "nav-link btn-nav"}
                                onClick={() => setState(2)}
                                aria-current="page" >System Details</button>
                        </li>
                    </ul>

                    {state === 0 ?
                        <div className="card border-1 p-md-3 p-1"
                            style={{ borderTop: "0", borderRadius: "0 0 .2em .2em", overflow: 'hidden' }}>
                            <IpDetails
                                ipDetails={ipDetails}
                                host={hostName}
                                test={false}
                            />
                        </div>
                        : state === 1 ?
                            <div style={{ height: "440px" }}>
                                <Location
                                    lat={ipDetails.location ? ipDetails.location.latitude : 32.00}
                                    lng={ipDetails.location ? ipDetails.location.longitude : 32.00} />
                            </div>
                            :
                            <div className="card border-1 p-3"
                                style={{ borderTop: "0", borderRadius: "0 0 .2em .2em" }}>
                                <SystemDetails
                                    headers={ipDetails.headers}
                                    userAgent={ipDetails.userAgent} />
                            </div>}

                </div>
                <div className="col-12 col-lg-6 d-flex mb-3 mb-lg-0 order-lg-2 order-1"
                    style={{
                        flexDirection: "column",
                    }}>
                    <div className="mx-auto mb-3">
                        <Link to="/dns-leak-test" className="text-center">
                            <StartButton />
                        </Link>
                    </div>
                    <h1 className="mb-3 text-h1-header">DNS Privacy </h1>
                    <p className="text-4">DNS was founded back in the 1970s along with the internet. Internet is run by IP communication, but DNS is essential to connect to the correct IP address by using Domain Names. Internet without DNS would not work at all; almost everything is dependant on the DNS Service. Despite this fact, today's DNS Infrastructure is run without encryption or privacy. All your uplinks can see (and maybe retain) which sites you visit before you even connect to these websites. This brings many problems such as privacy, <a href='https://en.wikipedia.org/wiki/DNS_spoofing' rel="noreferrer" target="_blank">DNS poisoning</a>, <a href='https://en.wikipedia.org/wiki/Man-in-the-middle_attack' rel="noreferrer" target="_blank">man-in-the-middle attacks</a>, data breach, filtering, or even phishing attacks. Therefore, you should care about your DNS Privacy and your Web History.</p>
                </div>
            </div>
        </div>

    )
}

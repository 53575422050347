import React, { useContext, useEffect } from 'react'
import Header from './navigation/Header'
import Footer from './navigation/Footer'
import Backdrop from './UI/Backdrop'
import { ipDetailsContext } from '../context/dnsServers';
import { getIpDetails, pingApi, setIdentfy } from './test/helper';
import { useRouteMatch } from "react-router-dom";
import Loading from './UI/Loading';
import {
    useLocation
} from "react-router-dom";

const ga = process.env.REACT_APP_GA
const adsense = process.env.REACT_APP_ADSENSE

export default function Layout({ children }) {

    let match = useRouteMatch("/dns-leak-test/:id");
    const context = useContext(ipDetailsContext);
    const { isModal, modalBrowser, apiServer, isLoading, ipDetails } = context.state;

    let location = useLocation();
    useEffect(() => {
        if (location.pathname !== '/dns-leak-test') {
            setIdentfy('', context.dispatch)
        }
    }, [location])

    useEffect(() => {
        pingApi(context);
        const gtmScript = document.createElement('script');
        const customScript = document.createElement('script');
        const adsenseScript = document.createElement('script');
        const adScript = document.createElement('script');

        if (ga !== undefined) {
            gtmScript.async = true;
            gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${ga}`;
            customScript.textContent = `
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', '${ga}');
        `;

            // Append both scripts to the head
            document.body.appendChild(gtmScript);
            document.body.appendChild(customScript);
        }

        if (adsense !== undefined) {
            adsenseScript.async = true;
            adsenseScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            adsenseScript.crossOrigin = 'anonymous';
            adsenseScript.setAttribute('data-ad-client', adsense);
            document.head.appendChild(adsenseScript)

            adScript.textContent = `
        (adsbygoogle = window.adsbygoogle || []).push({});
        `;
            document.body.appendChild(adScript)

        }
    }, [])

    useEffect(() => {
        if (!match) getIpDetails(context, true, ipDetails);
    }, [match])



    return (
        <section className="main">
            {isModal && <Backdrop />}
            {(modalBrowser?.["mozilla"] || modalBrowser?.["edge"] || modalBrowser?.["google"] || modalBrowser?.["opera"] ||
                modalBrowser?.["brave"])
                && <Backdrop />}
            <div>
                <Header />
                {apiServer && !isLoading && children}
                {isLoading && <div className="text-center" style={{ marginTop: "8em" }}> <Loading /> </div>}
                {!apiServer && !isLoading && <div className="text-center my-5 pt-5"
                    style={{ fontSize: "1.8em" }}> The page is in maintance mode, Please try again later... </div>}
                {/* {adsense !== undefined && <ins className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-format="auto"
                    data-ad-client={adsense}
                    data-ad-slot={"2766573840"}>
                </ins>} */}
            </div>
            <Footer />
        </section>
    )
}

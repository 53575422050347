import React, { useEffect, useContext, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ipDetailsContext } from '../../context/dnsServers';
import { DNS_SERVERS, SET_IP_DETAILS} from '../../context/index';
import Testing from './Testing';
import ResultAnalysis from './ResultAnalysis';
import PreventingDnsLeak from './PreventingDnsLeak';


export default function DnsLeakTestShare() {

    let { id } = useParams();
    const context = useContext(ipDetailsContext);

    const { identifier, dnsServers } = context.state;
    const {dispatch} = context;
    

    const getSharedData = useCallback(async () => {
        const res = await axios.get(`/api/dns-shares/${id}.json`);
        let totalCount= 0;
        let leaktest = [];
        let user = {};
        if (res?.data?.retcode === 200 && res.data.data.leaktest.length > 0) {
            res.data.data.leaktest.forEach(item => {
                totalCount = totalCount+item.count
            })
            leaktest = res?.data?.data?.leaktest;
            user = res?.data?.data.user;
        }
        dispatch({
            type: DNS_SERVERS,
            payload: {data:leaktest , count: totalCount}
        })
        dispatch({
            type: SET_IP_DETAILS,
            payload: { data: user }
        })
    }, [dispatch, id])

    useEffect(() => {
        getSharedData();
    }, [getSharedData])


    return (
        <div className="container my-5">
            {dnsServers.length > 0 ?
            <>
            <Testing
                identifier={identifier}
                shared ={true}
            />
            <ResultAnalysis />
            <PreventingDnsLeak />
            </>:
            <div className="row align-items-center justify-content-center my-5">
                <div className="col-12 my-5">
                    <p className="text-center" 
                    style={{fontSize:"1.5em", fontWeight:"400"}}> 
                    We didn't find any Shared DNS Leak Test Record</p>
                    <p className="text-center" 
                    style={{fontSize:"1.5em", fontWeight:"400"}}> 
                    Can be a problem with your shared link, you must check your dns shared link...</p>
                </div>
            </div>
            }
        </div>
    )
}


export function validHostname(domainName) {
    var domainNameRegex = /^(?:[a-z0-9](?:[a-z0-9_]{0,61}[a-z0-9])?\.){0,126}(?:[a-z0-9](?:[a-z0-9]{0,61}[a-z0-9]))\.?$/i
    // is an ip host
    // if (net.isIPv4(domainName) || net.isIPv6(domainName.replace(/[\[\]]/g,''))) return true
    // remove last '.'
    domainName=domainName.replace(/\.$/,'')
    if (domainName.length < 1) return false
    if (domainName.length > 255) return false
    
    if (domainName.search(/\./)<0 && domainName.search(/^[0-9]+$/)===0) {
        // using numbers without . in body
        return false
    }
    
    return domainNameRegex.test(domainName)
}
import React, {useContext} from 'react'
import CardModal from './CardModal'
import Paragraf from './Paragraf'
import { ipDetailsContext } from '../../context/dnsServers';
import {
    MODAL_BROWSER
} from '../../context/index';
import PlayCircle from './PlayCircle';



export default function CardList(props) {


    const context = useContext(ipDetailsContext);
    const { modalBrowser } = context.state;
    const { dispatch } = context;


    return (
        <div className="card mt-lg-3 mt-2">
            {props.gif && <CardModal gif={props.gif} title={props.name} shown={modalBrowser[props.name]} />}
            <div className="card-header d-flex" style={{ alignItems: "center", justifyContent:"space-between" }}>
                <div className="d-flex align-items-center">
                     <div className="mr-3"> {props.children}  </div>  
                    <h3 className="mb-1"> {props.title} </h3> 
                </div>
                {props.gif && <button className="btn btn-nav p-relative" type="button"
                onClick={() => {
                    dispatch({
                        type: MODAL_BROWSER,
                        payload: {name: props.name, position: true}
                    })
                    
                }}> <PlayCircle /> <span className="valid-tooltip"> Instructions Video </span> </button>}
            </div>
            <div className="card-body p-lg-3 p-2">
                <div className="card-row no-gutters">
                    <div className="col-12 mt-2">
                        <Paragraf paragraf={props.note} />
                    </div>
                    <div className="col-12">
                        {props.items.length === 1 && <p>{props.items[0]} </p>}
                        <ol className="">
                            {props.items.length > 1 && props.items.map(item => <li style={{ lineHeight: "1.8" }} key={item}> {item.split('#')[0]}
                                <span style={{ fontWeight: "700" }}> {item.split('#')[1] && item.split('#')[1]} </span>
                                <span> {item.split('#')[2] && item.split('#')[2]} </span>
                            </li>)}
                        </ol>
                    </div>
                    <div className="col-12 text-bold">
                        {props.paragraf}
                    </div>
                    

                </div>
            </div>
        </div>
    )
}

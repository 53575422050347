import React, { useReducer, useCallback, useState, useContext } from 'react';
import { ipDetailsContext } from '../../../context/dnsServers';
import { MODAL_POS, DOH_RESULT_UPDATE_CUSTOM } from '../../../context/index';
import {getDohSpeedTest} from '../../test/helper'
import axios from 'axios';
import Input from '../Input';
import cn from 'classnames'

const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
const FORM_INPUT_RESET = 'FORM_INPUT_RESET';


const formReducer = (state, action) => {

    if (action.type === FORM_INPUT_UPDATE) {
        const updatedValues = {
            ...state.inputVal,
            [action.input]: action.value
        }
        const updatedValidities = {
            ...state.inputValidities,
            [action.input]: action.isValid
        }
        let updatedFormIsValid = true;
        for (const key in updatedValidities) {
            updatedFormIsValid = updatedFormIsValid && updatedValidities[key]
        }
        return {
            inputVal: updatedValues,
            inputValidities: updatedValidities,
            formIsValid: updatedFormIsValid
        }
    }
    else if (action.type === FORM_INPUT_RESET) {
        return {
            inputVal: {
                name: '',
                url: '',
            },
            inputValidities: {
                name: false,
                url: false,
            },
            formIsValid: false
        }
    }
    return state;
}


const AddDohServer = ({searchValue}) => {

    const [completed, setCompleted] = useState(false);
    const context = useContext(ipDetailsContext);
    let { isModal } = context.state;

    const setModalHandler = (pos) => {
        setCompleted(false);
        context.dispatch({
            type: MODAL_POS,
            payload: pos
        })
        dispatchFormState({
            type: FORM_INPUT_RESET
        });
        setCompleted(true); 
    }

    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputVal: {
            name: '',
            url: '',
        },
        inputValidities: {
            name: false,
            url: false,
        },
        formIsValid: false
    })

    const onChangeInputHandler = useCallback((inputIdentifier, inputValue, inputValidty) => {
        dispatchFormState({
            type: FORM_INPUT_UPDATE,
            value: inputValue,
            isValid: inputValidty,
            input: inputIdentifier
        });
    }, [dispatchFormState])


    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setCompleted(false);
        if (!formState.formIsValid) {
            return;
        }
        try {
            const data = { "name": formState.inputVal.name, "url": formState.inputVal.url };
            const jsonDat = JSON.stringify(data)
            document.cookie = `customdoh_${formState.inputVal.name}= ${formState.inputVal.url}; max-age=2592000`
            const dataTest = await getDohSpeedTest(formState.inputVal.url, searchValue, formState.inputVal.name);
            context.dispatch({
                type: DOH_RESULT_UPDATE_CUSTOM,
                payload: dataTest
            })
            const resp = await axios.post('/api/doh/custom/save', { data: jsonDat })
            if (resp.data.retcode === 200) {
                setCompleted(true);
                dispatchFormState({
                    type: FORM_INPUT_RESET
                });
            }
            setModalHandler(false);
        } catch (err) {


        }

    }


    return (

        <form className={cn("modal fade", {"show": isModal})} 
        role="dialog"
            style={isModal ? { display: "block" } : {}}
            aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
            onSubmit={onSubmitHandler}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Add DoH Url</h5>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setModalHandler(false)}
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Input
                            name="name"
                            label="Provider Name"
                            type="text"
                            onChangeInput={onChangeInputHandler}
                            initialValue={formState.inputVal.name}
                            initialValid={formState.inputValidities.name}
                            required
                            completed={completed}
                            min={3}
                        />
                        <Input
                            name="url"
                            label="DoH Server Url"
                            type="url"
                            onChangeInput={onChangeInputHandler}
                            initialValue={formState.inputVal.url}
                            initialValid={formState.inputValidities.url}
                            required
                            completed={completed}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                            onClick={() => setModalHandler(false)}
                            className="btn btn-secondary">Close</button>
                        <button type="submit"
                            disabled={!formState.formIsValid}
                            className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default AddDohServer;

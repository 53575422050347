import React from 'react'
import Paragraf from '../UI/Paragraf'
import DNSLEAK from '../../assets/dnsleakimg2.png'


export default function HomeSecond() {
    return (
        <div className="container my-5">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="text-card-header"> What is a DNS Leak ?</h3>
                        </div>
                        <div className="card-body">
                            <div className="row no-gutters">
                                <div className="col-12 col-lg-12 p-3">
                                    <Paragraf paragraf="
                                    DNS Queries that go wild are considered as leaking DNS. Why is this a problem? At some point, you have to give your DNS Query Data to some company that runs the DNS. Even with the latest affords, the DNS Data is still readable to DNS running company. This can be your ISP, VPN Provider, or even GSM Providers. DNS Settings can be on your host, router, app, or browser. Imagine you pick your trusted provider for your DNS Data, and all of a sudden, your DNS queries go to other providers. This is an issue. We are here to make such tests. You should test your infrastructure regularly since DNS Settings can be set wrong in many places, and sometimes they may interfere. You are the right to pick your own DNS Provider and have the right that your DNS Queries do not go wild. The reasons might be misconfiguration, abuse of software/app, or even countries with bad intentions for your privacy.
                                    "/>
                                    <img src={DNSLEAK} alt="dns-leak-test" width="100%"/>
                                </div>
                                {/* <div className="col-12 col-lg-6 p-2 p-relative" style={{height:"100%"}}>
                                    <img src={DNSLEAK} alt="dns-leak-test" style={{width:"100%", height:"100%"}}/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
    
    )
}

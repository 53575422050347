import { DnsServerProvider } from './context/dnsServers';
import './App.css';
import React, { Suspense } from 'react'
import {Switch, Route, Redirect, BrowserRouter as Router} from 'react-router-dom';
import Home from './components/home/Home';
import Layout from './components/Layout';
import PrivacyPolicy from './components/privacy/PrivacyPolicy';
import FixingDnsLeak from './components/fixing/FixingDnsLeak';
import ScrollToTop from './components/navigation/ScrollToTop';
import DnsLeakTest from './components/test/DnsLeakTest';
import DnsOverHttps from './components/dns-over-https/DnsOverHttps';
import DnsLeakTestShare from './components/test/DnsLeakTestShare';

export const ipDetailsContext = React.createContext();

const App = () => {


  let routes =
    (<Switch>
      <Route path='/dns-over-https' component={DnsOverHttps} />
        <Route path='/fixing-dns-leak' component={FixingDnsLeak} />
        <Route path={'/privacy-policy'} component={PrivacyPolicy} />
        <Route path='/dns-leak-test/:id' component={DnsLeakTestShare} />
        <Route exact path='/dns-leak-test' component={DnsLeakTest} />
        <Route exact path="/" component={Home} />
        <Route path="*"><Redirect to="/" /> </Route>
    </Switch>);

  return (

    <Router>
    <ScrollToTop />
    <DnsServerProvider>
      <Layout >
        <Suspense fallback={<div>Loading...</div>}>
          {routes}
        </Suspense>
      </Layout>
      </DnsServerProvider>
    </Router>
  )
}

export default App;

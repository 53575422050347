import * as React from 'react';
import * as actions from './index';

export const ipDetailsContext = React.createContext();

function rdapReducer(state, action) {
    switch (action.type) {
        case actions.CHECK_API_SERVER: {
            return {
                ...state,
                apiServer: action.payload,
            }
        }
        case actions.LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            }
        }
        case actions.SET_IP_DETAILS: {
            return {
                ...state,
                ipDetails: action.payload.data,
            }
        }
        case actions.SET_IP_DETAILS_CURRENT: {
            return {
                ...state,
                ipDetailsCurrent: action.payload.data,
            }
        }
        case actions.RESET_IDENTIFIER: {
            return {
                ...state,
                identifier: action.payload,
            }
        }
        case actions.DNS_SERVERS: {
            return {
                ...state,
                dnsServers: action.payload.data,
                count: action.payload.count
            }
        }
        case actions.DNS_SERVERS_CURRENT: {
            return {
                ...state,
                dnsServersCurrent: action.payload.data,
                countCurrent: action.payload.count
            }
        }
        case actions.DOH_SERVERS: {
            return {
                ...state,
                dohServers: action.payload
            }
        }
        case actions.DOH_RESULT: {
            return {
                ...state,
                dohResult: action.payload
            }
        }
        case actions.SORT_DOH_RESULT: {
            return {
                ...state,
                dohResult: state.dohResult.sort((a, b) => action.payload ? b.latency - a.latency : a.latency - b.latency),
                dohResultCustom: state.dohResultCustom.sort((a, b) => action.payload ? b.latency - a.latency : a.latency - b.latency),
                isUp: action.payload
            }
        }
        case actions.DOH_RESULT_ADD_CUSTOM: {
            return {
                ...state,
                dohResultCustom: action.payload,
            }
        }
        case actions.DOH_RESULT_UPDATE_CUSTOM: {
            return {
                ...state,
                dohResultCustom: [...state.dohResultCustom, action.payload],
            }
        }
        case actions.DOH_RESULT_DELETE_CUSTOM: {
            const newData = state.dohResultCustom.filter(item => item.provider !== action.payload.name)
            return {
                ...state,
                dohResultCustom: newData,
            }
        }
        case actions.HOSTNAME: {
            return {
                ...state,
                hostName: action.payload
            }
        }
        case actions.RDAP: {
            return {
                ...state,
                rdap: { ...state.rdap, [action.payload.ip]: action.payload.data }
            }
        }
        case actions.CLOSE: {
            return {
                ...state,
                isShared: false,
                isSaved: false,
            }
        }
        case actions.IS_SAVED: {
            return {
                ...state,
                isSaved: action.payload
            }
        }
        case actions.IS_SHARED: {
            return {
                ...state,
                isShared: action.payload
            }
        }
        // case actions.IS_FIRST_RUN: {
        //     return {
        //         ...state,
        //         isFirstRun: action.payload
        //     }
        // }
        case actions.IS_FIRST_RUN_DOH: {
            return {
                ...state,
                isFirstRunDoh: action.payload
            }
        }
        case actions.MODAL_POS: {
            return {
                ...state,
                isModal: action.payload
            }
        }
        case actions.MODAL_BROWSER: {
            return {
                ...state,
                modalBrowser: {
                    ...state.modalBrowser,
                    [action.payload.name]: action.payload.position
                },
            }
        }
        case actions.MODAL_BROWSER_CLEAR: {
            return {
                ...state,
                modalBrowser: {
                    mozilla: false,
                    google: false,
                    edge: false,
                    opera: false,
                    brave: false,
                    safari: false
                }
            }
        }
        case actions.IS_SAVED_DOH: {
            return {
                ...state,
                isSavedDoh: action.payload
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}


function DnsServerProvider({ children }) {
    const [state, dispatchState] = React.useReducer(rdapReducer, {
        isLoading: false,
        apiServer: false,
        ipDetails: {},
        ipDetailsCurrent: {},
        hostName: "",
        dnsServers: [],
        dnsServersCurrent: [],
        dohServers: [],
        dohResult: [],
        dohResultCustom: [],
        rdap: {},
        isShared: false,
        isSaved: false,
        // isFirstRun: true, 
        isModal: false,
        isFirstRunDoh: true,
        identifier: "",
        isSavedDoh: false,
        isUp: false,
        count: 0,
        countCurrent: 0,
        modalBrowser: {
            mozilla: false,
            google: false,
            edge: false,
            opera: false,
            brave: false,
            safari: false
        }

    }
    );

    return <ipDetailsContext.Provider value={{ state: state, dispatch: dispatchState }}>
        {children}
    </ipDetailsContext.Provider>
}


export { DnsServerProvider }
import React, { useContext } from 'react'
import { ipDetailsContext } from '../../context/dnsServers';
// import {  RDAP } from '../../context/index';
// import { doRequest } from './doRequest';

const DnsServer = ({ cc, ip, cityName, countryName, hostName, percent, asn }) => {

  const context = useContext(ipDetailsContext);
  const { rdap } = context.state;
  // const {dispatch} = context;

  // const getRdap = useCallback(async () => {
  //   console.log("hostanem", hostName )
  //   try {
  //     if (hostName === undefined) {
  //       // const resp = await axios.get(`https://api.ip8.com/rdap/lookup/${ip}`);
  //       const resp = await doRequest(`/api/dnsecho/check/${ip}`, {signal: cnt >= 10 && signal}, "GET" )
  //       console.log("res", resp)
  //       if (resp.data.retcode === 200) {
  //         dispatch({
  //           type: RDAP,
  //           payload: { ip: ip, data: resp.data.data.name }
  //         })
  //       }
  //     }
  //   } catch (_) {

  //   }
  // },[hostName, ip, dispatch])

  // useEffect(() => {
  //   getRdap();
  // }, [getRdap])

  return (
    <tr className="table-row table-element" style={{ overflowY: "scroll" }}>
      <td className="text-left pl-3">{ip ? ip : "-"}</td>
      <td className="text-left">{percent ? percent : '-'} %</td>
      <td className="text-left">{cityName ? cityName : '-'}</td>
      <td className="text-left">
        <img
          className="mr-2"
          alt={cc} width={24} height={16}
          src={`/flags/${cc.toLowerCase()}.png`} /> {countryName ? countryName : ''}</td>
      <td className="text-left">{hostName ? hostName : rdap?.[ip] ?? <span style={{ paddingRight: "2em" }}> - </span>}</td>
      <td className="text-right">{asn}</td>
    </tr>
  )
}

export default DnsServer;

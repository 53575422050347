import React from 'react'

export default function Opera() {
    return (
        <svg height="50px" style={{enableBackground:"new 0 0 512 512"}} version="1.1" viewBox="0 0 512 512" width="50px" 
        space="preserve" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g id="_x35_5-opera"><g><g><path d="M177.471,121.184c21.896-25.929,50.166-41.542,81.066-41.542     c69.457,0,125.77,78.958,125.77,176.358c0,97.402-56.313,176.386-125.77,176.386c-30.9,0-59.17-15.647-81.066-41.539     c34.236,44.558,85.119,72.818,141.937,72.818c34.953,0,67.641-10.705,95.621-29.312c48.824-43.809,79.574-107.478,79.574-178.354     s-30.75-134.549-79.574-178.327c-27.98-18.605-60.668-29.311-95.621-29.311C262.59,48.333,211.68,76.623,177.471,121.184" 
            style={{fill:"#E53333"}}/><path d="M256.002,16.75C124.23,16.75,17.397,123.872,17.397,256     c0,128.297,100.749,233.025,227.242,238.978c3.76,0.178,7.543,0.272,11.363,0.272c61.08,0,116.793-23.032,158.994-60.896     c-27.975,18.606-60.662,29.312-95.619,29.312c-56.814,0-107.697-28.261-141.935-72.818     c-26.395-31.224-43.489-77.37-44.625-129.198v-11.268c1.168-51.803,18.258-97.975,44.625-129.197     c34.238-44.562,85.121-72.822,141.935-72.822c34.957,0,67.645,10.706,95.619,29.311c-41.963-37.682-97.35-60.685-158.105-60.922     H256.002z M256.002,16.75" 
        style={{fill:"#F84437"}}/></g></g></g><g id="Layer_1"/></svg>
    )
}

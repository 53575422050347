import React from 'react'

export default function StartButton() {
    return (
        <svg id="a5fedbdb-beaa-4db0-b578-c97884225e24" 
        width="192"
        height="192"
        className="start-shield"
        data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 240.56 288.42">
            <path d="M82.45,96.19S81.2,208.27,153.77,244.9c0,0,68.95-27.34,71.32-148.71l-71.32-35.1Z" 
            transform="translate(-33.95 -4.95)" 
            className="start-button" />
            <g>
                <path d="M66.33,200.41a317.32,317.32,0,0,1-17.48-45.89s-6.69-22.81-11.94-57.58c-.54-3.56-1-7-1-7-.3-2.36-.58-4.71-.83-7.08s-.45-4.74-.63-7.12-.33-4.71-.41-7.27L34,65l3.16-1.59L65.63,49,94.2,34.71l28.61-14.24L151.46,6.31,154.21,5,157,6.31l28.65,14.16L214.24,34.7,242.81,49l28.52,14.41L274.5,65l-.11,3.39c-.08,2.56-.23,4.87-.41,7.27,0,0-.25,3.47-.63,7.12a427.86,427.86,0,0,1-10.56,57.63,376.53,376.53,0,0,1-18.1,52.18l-12-.08A477.51,477.51,0,0,0,253.57,132s7.41-27.7,9.2-50.11c.27-3.45.43-6.86.43-6.86.12-2.25.21-4.61.22-6.72l3.06,5L237.66,59.47l-28.77-13.9-28.74-14L151.46,17.53h4.28L127.29,31.46,98.81,45.31,70.29,59.08,41.73,72.77l3-4.93c0,2.09.11,4.43.22,6.66s.26,4.53.43,6.79c.35,4.54.77,9.08,1.29,13.61l.67,5.78a373.66,373.66,0,0,0,29.07,94.9Z" 
                transform="translate(-33.95 -4.95)"
                className="start-shield-outer" />
                <path d="M244.69,192.62c-1.16,2.59-2.32,5.19-3.49,7.78a237.82,237.82,0,0,1-15.63,27.21,208.09,208.09,0,0,1-19.35,24.88,192.72,192.72,0,0,1-23.06,21.64,191.2,191.2,0,0,1-26.37,17.56l-3,1.67-3-1.67a190.39,190.39,0,0,1-49.43-39.19A209,209,0,0,1,82,227.62a237.82,237.82,0,0,1-15.63-27.21l10.1-4.83A244.69,244.69,0,0,0,91.8,221.11a213.4,213.4,0,0,0,18.53,23.14,190.35,190.35,0,0,0,21.71,20,180.28,180.28,0,0,0,24.58,16.26h-5.69a179.35,179.35,0,0,0,24.58-16.26,188.46,188.46,0,0,0,21.71-20,212.09,212.09,0,0,0,18.53-23.13,244.69,244.69,0,0,0,15.37-25.53l1.61-3.06h3.52Z" transform="translate(-33.95 -4.95)" 
                className="start-shield-outer"/>
            </g>
            <text transform="translate(65.00 128.24)"
            className="start-button-text" 
            style={{fontSize: "1.5rem", fill: "#fff", fontWeight:"600"}}>Start DNS </text>
            <text transform="translate(68.50 168.24)" 
            className="start-button-text"
            style={{fontSize: "1.5rem", fill: "#fff", fontWeight:"600"}}>Leak Test </text>
        </svg>

    )
}

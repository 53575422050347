import React from 'react';

export default function Paragraf({ paragraf, intent, split, splitOne, style }) {
    return (
        split ? <p className="text-4" style={intent ? { textIndent: "1em", textAlign: "justify", ...style } : 
        { textAlign: "justify", ...style }}>
            <span style={{ fontWeight: 600 }}>  {splitOne} </span> {paragraf} </p> :
            <p  className="text-4" style={intent ? { textIndent: "1em", textAlign: "justify", ...style } : 
            { ...style }}> {paragraf} </p>
    )
}
import React from 'react'
import TrashIcon from '../UI/TrashIcon'
import cn from 'classnames'

const ResultItem = ({ provider, url, latency, result, slot,
    plus, custom, onClickHandlerDel }) => {

    return (
        <tr className={cn("d-table-row table-element", { "bg-warning": custom })}>
            <th className="d-flex">{provider} {custom ? "(*C)" : ""} </th>
            <td>{url}</td>
            <td>{latency === "" ? "-" : `${latency} ms`} </td>
            <td className="d-flex text-bold text-secondary ip-item"
                style={result === "Failed" ?
                    { color: "red !important", position: "relative" } : { position: "relative" }}>
                {result}
                <span className="text-primary"> {slot ? `+ ${slot}` : ""} </span>
                {plus.length > 0 && <span className="plus card bg-light">
                    {plus.map(item => <span className="text-secondary" style={{ fontSize: ".725em" }}
                        key={item.data}> {item.data} </span>)}
                </span>}
                {custom && <span
                    className="dohserver-delete"
                    onClick={() => onClickHandlerDel(provider)}
                    type="button"
                ><TrashIcon />
                </span>}
            </td>
        </tr>
    )
}
export default ResultItem;
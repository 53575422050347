import React from 'react';
import HomeFirst from './HomeFirst';
import HomeSecond from './HomeSecond';
import HomeThird from './HomeThird';

export default function Home() {
    return (
        <section>
            <HomeFirst />
            <HomeSecond />
            <HomeThird />
        </section>
    )
}

import React, { useContext } from 'react'

import DnsServer from './DnsServer';
import axios from 'axios';
import { ipDetailsContext } from '../../context/dnsServers';
import { DNS_SERVERS, SET_IP_DETAILS } from '../../context/index';
import { wait } from './helper';


const TestPoller = ({ dnsservers, dataPast, active,
  setActive, getCookies, setCont, isLoading, shared }) => {

  const context = useContext(ipDetailsContext);
  const { dnsServersCurrent, ipDetailsCurrent, count, countCurrent } = context.state;
  const { dispatch } = context;

  const deleteHandler = (name) => {
    document.cookie = name + '=; max-age=-0;';
    getCookies();
    if (shared) return setActive(-1)
    setActive(0);
    onfetchSavedData("current");
  }

  const onfetchSavedData = async (param) => {
    if (param === "current") {
      dispatch({
        type: SET_IP_DETAILS,
        payload: { data: ipDetailsCurrent }
      })
      return dispatch({
        type: DNS_SERVERS,
        payload: { data: dnsServersCurrent, count: countCurrent }
      })
    }
    if (isLoading) {
      setCont(11);
      await wait(5);
    }
    const res = await axios.get(param);
    let totalCount = 0;
    let leaktest = [];
    let user = {}
    if (res.data.retcode === 200 && res.data.data.leaktest.length > 0) {
      res.data.data.leaktest.forEach(item => {
        totalCount = totalCount + item.count
      })
      leaktest = res.data.data.leaktest;
      user = res.data.data.user;
    }
    dispatch({
      type: DNS_SERVERS,
      payload: { data: leaktest, count: totalCount }
    })
    dispatch({
      type: SET_IP_DETAILS,
      payload: { data: user }
    })
  }


  const navItem =
    dataPast.length > 0 ?
      dataPast.map((item, i) => {
        return (
          <li className={active === i + 1 ? "px-0 mx-0 col-4 nav-item d-flex justify-content-between align-items-center nav-link-tab active-tab" :
            "px-0 mx-0 col-4 nav-item d-flex align-items-center nav-link-tab"}
            // style={{ justifyContent: "space-between" }}
            key={item.date}
            >
            <button
              className="nav-link-tab-item"
              onClick={() => {
                setActive(i + 1);
                onfetchSavedData(item.name)
              }}
            > {item.date} </button>
            <button className="btn btn-sm btn-delete"
              onClick={() => deleteHandler(item.date)}>&times;</button>
          </li>)
      }) : null;





  return (
    <div className="pt-0">
      {/* {children} */}
      {dataPast.length > 0 || shared ?
        <div className="card pt-3" style={shared ? { backgroundColor: "#39B54A", color: "white" } :
         { backgroundColor: "#E8F1F2" }}>
          <h5 className="text-center text-bold pl-2 pb-2"> 
          {shared ? "Shared" : "Your Last"} DNS Leak Test  {shared ? "Record" : "Records"} </h5>
          <ul className="row no-gutters nav nav-tabs">
            {!shared && navItem}
            {!shared &&
              <li className={active === 0 ? "px-0 mx-0 col-4 nav-item nav-link-tab active-tab" :
                "px-0 mx-0 col-4 nav-item nav-link-tab"}>
                <button
                  className="nav-link-tab-item"
                  onClick={() => {
                    setActive(0);
                    onfetchSavedData("current");
                  }}
                >  Current Test</button>
              </li>}
          </ul>
        </div> : null}
      <div className="card" id="saved-container" style={{ width: "100%", overflowX: "scroll" }}>
        <table className="table table-hover" data-test='ip_table'>
          <tbody className="table-body">
            <tr className="table-row card-header">
              <td className="text-left text-bold"> IP Address </td>
              <td className="text-left text-bold"> Percent </td>
              <td className="text-left text-bold"> City </td>
              <td className="text-left text-bold"> Country </td>
              <td className="text-left text-bold"> ISP </td>
              <td className="text-right text-bold pr-5"> ASN ORG </td>
            </tr>
            {dnsservers.map((element, i) => {
              let percentage = ((element?.count / count) * 100).toFixed(2);
              return (<DnsServer
                countryName={element?.countryName}
                cityName={element?.cityName}
                percent={percentage}
                key={element?.order ?? i}
                ip={element?.ip}
                cc={element?.cc}
                hostName={element?.isp}
                asn={element?.asnOrg}
                location={element?.location?.['latitude'] + "-" + element?.location?.['longitude']}/>)
            })}
          </tbody>
        </table>
      </div>
    </div>
  )

}


export default TestPoller;
import React from 'react'
import Card from '../UI/Card'

export default function ResultAnalysis() {
    return (
        <div className="row mt-lg-5 mt-3">
            <div className="col">
                <Card 
                title="Understand the Test Results" 
                paragraf="You should look for discrepancies between your current known DNS setup and actual test results. For example, if you have set up your ISP as your DNS Settings (which is, for most internet users, the default case), then you should not see other DNS Servers. If you are on a VPN Connection, you should only see their DNS Servers and not your ISPs. If you see both, then this is also a DNS Leak. Some VPN Providers might also use other DNS Servers than their own; you should avoid such providers; imagine you are connected to a VPN Server in the UK, and your DNS Servers appear to be in the US. This is an issue because this is then a location leak. Every website you visit could do such DNS Leak tests and see that your IP is in the UK but using DNS Servers in the US. This might not look realistic to them. You will need a little bit of technical knowledge about DNS and how to set them up on the client-side to catch sophisticated DNS Leak cases, but generally, this test will help you a lot to catch most of the tests. Do not hesitate to make multiple tests at multiple times. Some leak configurations might occur at busy times because some apps, browsers, routers might route the DNS traffic somewhere else at busy times.

                "
                />
            </div>
        </div>
    )
}

import React from 'react'
import {Link} from 'react-router-dom'
import Card from '../UI/Card'

export default function PreventingDnsLeak() {
    
    return (
        <div className="row mt-lg-5 mt-3">
            <div className="col">
                <Card title="How can you prevent a DNS Leak ?" 
                paragraf={<span>Preventing is not as easy as testing for most internet users because they sometimes have limited rights to change or look up their settings. Please go through our <Link to='/fixing-dns-leak' >Fixing DNS Leak Article</Link> for best understanding. </span>}/>
            </div>
        </div>
    )
}

import axios from 'axios';

const instance = axios.create({
    timeout: 30000
})

export const doRequest = async (fullPath, dataObject = {},
    method = "POST",
    additionalHeaders = {}) => {
    const headers = {
        ...additionalHeaders,
    }
    const data = dataObject
    // const api = process.env.REACT_APP_API_KEY

    // const url = `${api}/${path}`;
    const options = {
        method,
        // url: fullPath,
        url:
            fullPath.includes("https://") || fullPath.includes("http://")
                ? fullPath
                : `${fullPath}`,
        data: method === "POST" ? { ...data } : false,
        params: method === "GET" ? dataObject : false,
        headers,
    }

    try {
        const response = await instance(options)
        if(method === 'GET') {
            return response.data
        } else {
            return response
        }
    } catch (err) {
      
    }
    return false

}